export const locations = [
  {
    id: "location-0",
    Name: "Woodberry Kitchen Tavern",
    Neighborhood: "Woodberry",
    Type: "American, Farm-to-Table",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/woodberry-kitchen-baltimore-5",
    "Instagram URL": "https://www.instagram.com/woodberrykitchen/?hl=en",
    Lat: 39.3320685,
    Lon: -76.6453523,
  },
  {
    id: "location-1",
    Name: "Marie Louise Bistro",
    Neighborhood: "Mount Vernon",
    Type: "French Bistro",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/marie-louise-bistro-baltimore",
    "Instagram URL": "https://www.instagram.com/marielouisebistro/",
    Lat: 39.299863,
    Lon: -76.6159377,
  },
  {
    id: "location-2",
    Name: "The Copper Shark",
    Neighborhood: "Locust Point",
    Type: "Seafood, American",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/the-copper-shark-baltimore-2",
    "Instagram URL":
      "https://www.instagram.com/thecoppersharklocustpoint/?hl=en",
    Lat: 39.2710415,
    Lon: -76.6006199,
  },
  {
    id: "location-3",
    Name: "Shoyou Sushi",
    Neighborhood: "Federal Hill",
    Type: "Sushi, Japanese",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/shoyou-sushi-baltimore",
    "Instagram URL": "https://www.instagram.com/sushibruce/?hl=en",
    Lat: 39.2728789,
    Lon: -76.6118961,
  },
  {
    id: "location-4",
    Name: "Urban Oyster",
    Neighborhood: "Hampden",
    Type: "Seafood, Oyster Bar",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/the-urban-oyster-baltimore-6",
    "Instagram URL": "https://www.instagram.com/theurbanoyster/",
    Lat: 39.3312807,
    Lon: -76.6321962,
  },
  {
    id: "location-5",
    Name: "La Calle",
    Neighborhood: "Fells Point",
    Type: "Mexican, Upscale",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/la-calle-baltimore",
    "Instagram URL": "https://www.instagram.com/lacalle_baltimore/",
    Lat: 39.2839299,
    Lon: -76.5930919,
  },
  {
    id: "location-6",
    Name: "Peter's Inn",
    Neighborhood: "Fells Point",
    Type: "American, Contemporary",
    Price: "$$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/peters-inn-baltimore",
    "Instagram URL": "https://www.instagram.com/petersinnbaltimore/",
    Lat: 39.2854533,
    Lon: -76.5915703,
  },
  {
    id: "location-7",
    Name: "The Arthouse",
    Neighborhood: "Hampden",
    Type: "Pizza, Italian",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/the-arthouse-baltimore",
    "Instagram URL": null,
    Lat: 39.3308381,
    Lon: -76.6343312,
  },
  {
    id: "location-8",
    Name: "Water for Chocolate",
    Neighborhood: "Upper Fells Point",
    Type: "American",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/water-for-chocolate-baltimore",
    "Instagram URL": "https://www.instagram.com/waterforchocolatellc/?hl=en",
    Lat: 39.2903816,
    Lon: -76.5905237,
  },
  {
    id: "location-9",
    Name: "Thai Arroy",
    Neighborhood: "Federal Hill",
    Type: "Thai",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/thai-arroy-baltimore",
    "Instagram URL": "https://www.instagram.com/thaiarroy/?hl=en",
    Lat: 39.277866,
    Lon: -76.612267,
  },
  {
    id: "location-10",
    Name: "Charming Elephant",
    Neighborhood: "Fells Point",
    Type: "Laotian/Thai",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/charming-elephant-baltimore",
    "Instagram URL": "https://www.instagram.com/charming.elephant/",
    Lat: 39.2828594,
    Lon: -76.5836353,
  },
  {
    id: "location-11",
    Name: "Mayuree Thai",
    Neighborhood: "Canton",
    Type: "Thai",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/mayuree-thai-tavern-baltimore",
    "Instagram URL": "https://www.instagram.com/mayureethaitavern/?hl=fr",
    Lat: 39.2851029,
    Lon: -76.5836441,
  },
  {
    id: "location-12",
    Name: "Bmore Taqueria",
    Neighborhood: "Fells Point",
    Type: "Mexican",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/bmore-taqueria-baltimore",
    "Instagram URL": null,
    Lat: 39.2854633,
    Lon: -76.5919206,
  },
  {
    id: "location-13",
    Name: "Tortilleria Sinaloa",
    Neighborhood: "Fells Point",
    Type: "Mexican",
    Price: "$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/tortilleria-sinaloa-baltimore",
    "Instagram URL": "https://www.instagram.com/tortilleriasinaloa/",
    Lat: 39.2857472,
    Lon: -76.5925167,
  },
  {
    id: "location-14",
    Name: "LP Steamers",
    Neighborhood: "Locust Point",
    Type: "Crabs, Seafood",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/l-p-steamers-baltimore",
    "Instagram URL": "https://www.instagram.com/locustpointsteamers/?hl=en",
    Lat: 39.2708919,
    Lon: -76.5986557,
  },
  {
    id: "location-15",
    Name: "The Food Market",
    Neighborhood: "Hampden",
    Type: "American, Contemporary",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/the-food-market-baltimore",
    "Instagram URL": null,
    Lat: 39.3308889,
    Lon: -76.6332417,
  },
  {
    id: "location-16",
    Name: "Mi & Yu Noodles",
    Neighborhood: "Hampden",
    Type: "Noodles, Ramen, Bao Buns",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/mi-and-yu-noodle-bar-baltimore-3",
    "Instagram URL": "https://www.instagram.com/miandyunoodlebar/",
    Lat: 39.3359294,
    Lon: -76.6300107,
  },
  {
    id: "location-17",
    Name: "Clavel",
    Neighborhood: "Remington",
    Type: "Mexican, Mezcaleria",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/clavel-baltimore",
    "Instagram URL": "https://www.instagram.com/bar_clavel/",
    Lat: 39.3150317,
    Lon: -76.6204571,
  },
  {
    id: "location-18",
    Name: "Little Donna's",
    Neighborhood: "Fells Point",
    Type: "Pizza, Polish, Cocktail Bar",
    Price: "$$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/little-donnas-baltimore",
    "Instagram URL": "https://www.instagram.com/littledonnas/?hl=en",
    Lat: 39.2869055,
    Lon: -76.5909075,
  },
  {
    id: "location-19",
    Name: "Papi's Tacos",
    Neighborhood: "Fells Point",
    Type: "Mexican",
    Price: "$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/papis-tacos-baltimore",
    "Instagram URL": "https://www.instagram.com/papistacosfells/?hl=en",
    Lat: 39.283435,
    Lon: -76.592839,
  },
  {
    id: "location-20",
    Name: "Cocina Luchadores",
    Neighborhood: "Federal Hill",
    Type: "Mexican",
    Price: "$",
    Category: "Restaurant",
    "Yelp URL": "https://www.yelp.com/biz/cocina-luchadoras-baltimore",
    "Instagram URL": "https://www.instagram.com/cocinaluchadoras/",
    Lat: 39.2877891,
    Lon: -76.5933148,
  },
  {
    id: "location-21",
    Name: "Matthew's Pizza",
    Neighborhood: "Highlandtown",
    Type: "Pan Pizza",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/matthews-pizza-baltimore",
    "Instagram URL": "https://www.instagram.com/matthews_pizza/",
    Lat: 39.2862517,
    Lon: -76.5718847,
  },
  {
    id: "location-22",
    Name: "Underground Pizza",
    Neighborhood: "Mount Vernon",
    Type: "Detroit Style",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/underground-pizza-baltimore-2",
    "Instagram URL": "https://www.instagram.com/undergroundpizzamd/",
    Lat: 39.2890512,
    Lon: -76.6072655,
  },
  {
    id: "location-23",
    Name: "Key Neapolitan",
    Neighborhood: "Federal Hill",
    Type: "Neapolitan",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/key-neapolitan-baltimore",
    "Instagram URL": "https://www.instagram.com/keyneapolitan/?hl=en",
    Lat: 39.2747771,
    Lon: -76.6051441,
  },
  {
    id: "location-24",
    Name: "Limoncello Pizzeria",
    Neighborhood: "Little Italy",
    Type: "NY Slice Shop, New York or Sicillian/Grandma",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/limoncello-pizzeria-baltimore",
    "Instagram URL": "https://www.instagram.com/limoncellopizzeriabmore/?hl=en",
    Lat: 39.271839246277445,
    Lon: -76.60107878926053,
  },
  {
    id: "location-25",
    Name: "Paulie Gee's Pizza",
    Neighborhood: "Hampden",
    Type: "Wood-fired Pizza",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/paulie-gees-hampden-baltimore-3",
    "Instagram URL": "https://www.instagram.com/pauliegee/?hl=en",
    Lat: 39.3307659,
    Lon: -76.628959,
  },
  {
    id: "location-26",
    Name: "Verde",
    Neighborhood: "Canton",
    Type: "Neapolitan Pizza",
    Price: "$$",
    Category: "Pizza",
    "Yelp URL": "https://www.yelp.com/biz/verde-baltimore",
    "Instagram URL": "https://www.instagram.com/verdepizza/?hl=en",
    Lat: 39.2841108,
    Lon: -76.5825459,
  },
  {
    id: "location-27",
    Name: "Cross Street Market",
    Neighborhood: "Federal Hill",
    Type: "Variety of Food Stalls",
    Price: "$$",
    Category: "Marketplace",
    "Yelp URL": "https://www.yelp.com/biz/cross-street-market-baltimore",
    "Instagram URL": "https://www.instagram.com/crossstmarket/",
    Lat: 39.2767811,
    Lon: -76.6134205,
  },
  {
    id: "location-28",
    Name: "Broadway Market",
    Neighborhood: "Fells Point",
    Type: "Variety of Food Stalls",
    Price: "$$",
    Category: "Marketplace",
    "Yelp URL": "https://www.yelp.com/biz/broadway-market-baltimore-2",
    "Instagram URL": "https://www.instagram.com/broadwaymarketbaltimore/?hl=en",
    Lat: 39.2841931,
    Lon: -76.5934041,
  },
  {
    id: "location-29",
    Name: "Mount Vernon Marketplace",
    Neighborhood: "Mount Vernon",
    Type: "Variety of Food Stalls",
    Price: "$$",
    Category: "Marketplace",
    "Yelp URL": "https://www.yelp.com/biz/mount-vernon-marketplace-baltimore-2",
    "Instagram URL": "https://www.instagram.com/mountvernonmarketplace/?hl=en",
    Lat: 39.296075,
    Lon: -76.6184806,
  },
  {
    id: "location-30",
    Name: "R. House",
    Neighborhood: "Remington",
    Type: "Food Hall",
    Price: "$$",
    Category: "Marketplace",
    "Yelp URL": "https://www.yelp.com/biz/r-house-baltimore",
    "Instagram URL": "https://www.instagram.com/rhousebaltimore/?hl=en",
    Lat: 39.3217366,
    Lon: -76.6223048,
  },
  {
    id: "location-31",
    Name: "Lexington Market",
    Neighborhood: "Downtown",
    Type: "Variety of Food Stalls",
    Price: "$$",
    Category: "Marketplace",
    "Yelp URL": "https://www.yelp.com/biz/lexington-market-baltimore",
    "Instagram URL": "https://www.instagram.com/lexingtonmarket/",
    Lat: 39.2910717,
    Lon: -76.6216858,
  },
  {
    id: "location-32",
    Name: "Spirits Tavern",
    Neighborhood: "Fells Point",
    Type: "Historic Dive Bars, Pool Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/spirits-tavern-baltimore-2",
    "Instagram URL": null,
    Lat: 39.2865755,
    Lon: -76.589998,
  },
  {
    id: "location-33",
    Name: "Club Charles",
    Neighborhood: "Station North",
    Type: "Artsy/Dive Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/club-charles-baltimore",
    "Instagram URL": null,
    Lat: 39.3095271,
    Lon: -76.61674,
  },
  {
    id: "location-34",
    Name: "Ottobar",
    Neighborhood: "Remington",
    Type: "Historic Rock/Punk Venue, Pool Tables, Dance Floor",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/ottobar-baltimore",
    "Instagram URL": "https://www.instagram.com/theottobar/?hl=en",
    Lat: 39.318881,
    Lon: -76.6195818,
  },
  {
    id: "location-35",
    Name: "The Crown",
    Neighborhood: "Station North",
    Type: "Korean Food, Live Music",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/the-crown-baltimore-2",
    "Instagram URL": null,
    Lat: 39.3118298,
    Lon: -76.6167294,
  },
  {
    id: "location-36",
    Name: "Fraziers",
    Neighborhood: "Hampden",
    Type: "Pool Bar, Dive Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/fraziers-on-the-avenue-baltimore",
    "Instagram URL": null,
    Lat: 39.3309927,
    Lon: -76.6319971,
  },
  {
    id: "location-37",
    Name: "Slainte",
    Neighborhood: "Fells Point",
    Type: "Irish Pub",
    Price: "$$",
    Category: "Bar",
    "Yelp URL":
      "https://www.yelp.com/biz/sl%C3%A1inte-irish-pub-and-restaurant-baltimore-2",
    "Instagram URL": null,
    Lat: 39.2820547,
    Lon: -76.5925138,
  },
  {
    id: "location-38",
    Name: "Brewers Art",
    Neighborhood: "Mount Vernon",
    Type: "Brewery, Tavern, Brewpub",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/the-brewers-art-baltimore",
    "Instagram URL": null,
    Lat: 39.3027445,
    Lon: -76.6161317,
  },
  {
    id: "location-39",
    Name: "Jong Kak",
    Neighborhood: "Charles North",
    Type: "Korean BBQ, Upstairs Karaoke Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz_photos/jong-kak-baltimore-3",
    "Instagram URL": null,
    Lat: 39.3123186,
    Lon: -76.6174729,
  },
  {
    id: "location-40",
    Name: "Bluebird",
    Neighborhood: "Hampden",
    Type: "Cocktail Bar, Whiskey Pub, Speak-easy Vibes",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/the-bluebird-cocktail-room-baltimore",
    "Instagram URL": null,
    Lat: 39.3312405,
    Lon: -76.6339903,
  },
  {
    id: "location-41",
    Name: "Lil Phil's",
    Neighborhood: "Canton",
    Type: "Dive Bar",
    Price: "$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/lil-phils-tavern-baltimore",
    "Instagram URL": null,
    Lat: 39.2832402,
    Lon: -76.5937061,
  },
  {
    id: "location-42",
    Name: "Alexander's Tavern",
    Neighborhood: "Fells Point",
    Type: "Tavern",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/alexanders-tavern-baltimore",
    "Instagram URL": null,
    Lat: 39.2831984,
    Lon: -76.5935381,
  },
  {
    id: "location-43",
    Name: "Cat's Eye Pub",
    Neighborhood: "Fells Point",
    Type: "Live Music Pub",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/cats-eye-pub-baltimore",
    "Instagram URL": null,
    Lat: 39.2820731,
    Lon: -76.5915714,
  },
  {
    id: "location-44",
    Name: "Max's Taphouse",
    Neighborhood: "Fells Point",
    Type: "Craft Beer Bar, Pub",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/maxs-taphouse-baltimore-2",
    "Instagram URL": null,
    Lat: 39.2826448,
    Lon: -76.5930146,
  },
  {
    id: "location-45",
    Name: "Club Car (on North Avenue)",
    Neighborhood: "Station North",
    Type: "Cocktail Lounge, Pool Table, LGBT Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": null,
    "Instagram URL": null,
    Lat: 39.3114069,
    Lon: -76.6170344,
  },
  {
    id: "location-46",
    Name: "City Limits Sports Bar",
    Neighborhood: "Locust Point",
    Type: "Neighborhood Bar, Sports Bar",
    Price: "$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/city-limits-sports-bar-baltimore",
    "Instagram URL": null,
    Lat: 39.2681859,
    Lon: -76.590799,
  },
  {
    id: "location-47",
    Name: "Hull Street Blues",
    Neighborhood: "Locust Point",
    Type: "Neighborhood Pub, Shuffle Board",
    Price: "$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/hull-street-blues-cafe-baltimore-2",
    "Instagram URL": null,
    Lat: 39.2722482,
    Lon: -76.5920078,
  },
  {
    id: "location-48",
    Name: "Wiley Gunter's",
    Neighborhood: "Locust Point",
    Type: "Sports Bar, Washington Commander's Bar",
    Price: "$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/wiley-gunters-baltimore",
    "Instagram URL": null,
    Lat: 39.2717499,
    Lon: -76.60223,
  },
  {
    id: "location-49",
    Name: "W.C. Harlan",
    Neighborhood: "Remington",
    Type: "Speakeasy",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/wc-harlan-baltimore",
    "Instagram URL": "https://www.instagram.com/wcharlan/",
    Lat: 39.3152824,
    Lon: -76.6220817,
  },
  {
    id: "location-50",
    Name: "Rye Craft Cocktails",
    Neighborhood: "Fells Point",
    Type: "Cocktail Bar",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/rye-baltimore-2",
    "Instagram URL": null,
    Lat: 39.281477,
    Lon: -76.593501,
  },
  {
    id: "location-51",
    Name: "Ministry of Brewing",
    Neighborhood: "Butchers Hill",
    Type: "Brewery, Beer Hall",
    Price: "$$",
    Category: "Bar",
    "Yelp URL": "https://www.yelp.com/biz/ministry-of-brewing-baltimore",
    "Instagram URL": "https://www.instagram.com/ministryofbrewing/?hl=en",
    Lat: 39.2906896,
    Lon: -76.5900816,
  },
  {
    id: "location-52",
    Name: "Union Craft Brewing",
    Neighborhood: "Hampden",
    Type: "Brewery, Beer Hall/Garden",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": "https://www.yelp.com/biz/union-craft-brewing-baltimore",
    "Instagram URL": "https://www.instagram.com/unioncraftbrewing/?hl=en",
    Lat: 39.3365704,
    Lon: -76.6444502,
  },
  {
    id: "location-53",
    Name: "Brewer's Art",
    Neighborhood: "Mount Vernon",
    Type: "Fancy Brewpub, Modern European, Belgian",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": "https://www.yelp.com/biz/the-brewers-art-baltimore",
    "Instagram URL": "https://www.instagram.com/brewersart/?hl=en",
    Lat: 39.3027445,
    Lon: -76.6161317,
  },
  {
    id: "location-54",
    Name: "Monument City Brewing Company",
    Neighborhood: "Highlandtown",
    Type: "Brewery",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": "https://www.yelp.com/biz/monument-city-brewing-baltimore",
    "Instagram URL": "https://www.instagram.com/monumentcitybrewing/",
    Lat: 39.2925667,
    Lon: -76.5621268,
  },
  {
    id: "location-55",
    Name: "Peabody Heights Brewery",
    Neighborhood: "Abell",
    Type: "Brewery",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": null,
    "Instagram URL": "https://www.instagram.com/peabodyheightsbrewery/?hl=en",
    Lat: 39.3243423,
    Lon: -76.6104348,
  },
  {
    id: "location-56",
    Name: "Diamondback Brewing Company",
    Neighborhood: "Locust Point",
    Type: "Brewery, Brewpub, Beer Garden",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL":
      "https://www.yelp.com/biz/diamondback-brewing-company-baltimore",
    "Instagram URL": "https://www.instagram.com/dbcbeer/?hl=en",
    Lat: 39.268652,
    Lon: -76.5974877,
  },
  {
    id: "location-57",
    Name: "Guilford Hall Brewery",
    Neighborhood: "Station North",
    Type: "European Brewery and Restaurant and Biergarten",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": "https://www.yelp.com/biz/guilford-hall-brewery-baltimore",
    "Instagram URL": "https://www.instagram.com/guilfordhallbrewery/?hl=en",
    Lat: 39.3083025,
    Lon: -76.6120638,
  },
  {
    id: "location-58",
    Name: "Ministry of Brewing",
    Neighborhood: "Butchers Hill",
    Type: "Brewery, Beer Hall",
    Price: "$$",
    Category: "Brewery",
    "Yelp URL": "https://www.yelp.com/biz/ministry-of-brewing-baltimore",
    "Instagram URL": "https://www.instagram.com/ministryofbrewing/?hl=en",
    Lat: 39.2906896,
    Lon: -76.5900816,
  },
  {
    id: "location-59",
    Name: "Pitango Bakery + Cafe",
    Neighborhood: "Fells Point",
    Price: "$$",
    Type: "Bakery, Cafe",
    Category: "Coffee Shop",
    Lat: 39.2816508,
    Lon: -76.59096679999999,
  },
  {
    id: "location-60",
    Name: "Koba Cafe",
    Neighborhood: "Federal Hill",
    Type: "Breakfast, Cafe",
    Price: "$$",
    Category: "Coffee Shop",
    Lat: 39.27285,
    Lon: -76.604075,
  },
  {
    id: "location-61",
    Name: "Order and Chaos",
    Neighborhood: "Federal Hill",
    Type: "Coffee Shop, Cafe",
    Price: "$$",
    Category: "Coffee Shop",
    Lat: 39.2735141,
    Lon: -76.6027101,
  },
  {
    id: "location-62",
    Name: "Mindpub",
    Neighborhood: "Federal Hill",
    Type: "Brunch, Cafe, Breakfast Sandwiches",
    Category: "Coffee Shop",
    Price: "$$",
    Lat: 39.2731748,
    Lon: -76.6054152,
  },
  {
    id: "location-63",
    Name: "Daily Grind",
    Neighborhood: "Fells Point",
    Type: "Coffee Shop, Cafe",
    Category: "Coffee Shop",
    Price: "$$",
    Lat: 39.2820599,
    Lon: -76.59178469999999,
  },
  {
    id: "location-64",
    Name: "Ovenbird",
    Neighborhood: "Little Italy",
    Type: "Bakery, Cafe",
    Price: "$$",
    Category: "Coffee Shop",
    Lat: 39.2865928,
    Lon: -76.6007901,
  },
];
